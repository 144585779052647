import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import Header from "../../shared/Header";
import Image from "next/image";
import ShinyButton from "@/components/magicui/shiny-button";
import GridPattern from "@/components/magicui/grid-pattern";
import { cn } from "@/lib/utils";
import ShineBorder from "@/components/magicui/shine-border";
import { useRouter } from "next/router";
import NumberTicker from "@/components/magicui/number-ticker";
import { BorderBeam } from "@/components/magicui/border-beam";
import SparklesText from "@/components/magicui/sparkles-text";

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [viewportHeight, setViewportHeight] = useState("95vh");

  useEffect(() => {
    setIsVisible(true);

    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight * 0.96}px`);
    };

    // Set initial viewport height
    updateViewportHeight();

    // Update viewport height on resize
    window.addEventListener("resize", updateViewportHeight);

    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  return (
    <div
      className={`relative m-3 flex flex-col overflow-hidden rounded-[20px] bg-[url('https://cdn-crayo.com/lp/public/heroBg.png')] bg-cover bg-center transition-opacity duration-1000 ease-in-out md:m-5 md:rounded-[40px] ${isVisible ? "opacity-100" : "opacity-0"}`}
      style={{ height: viewportHeight }}
    >
      <GridPattern
        width={70}
        height={70}
        x={-1}
        y={-1}
        strokeDasharray={"4 2"}
        strokeWidth={1}
        className={cn(
          "absolute inset-0 z-0 [mask-image:radial-gradient(750px_circle_at_center,white,transparent)]",
        )}
      />
      <Header />
      <div className="flex flex-grow items-center justify-center">
        <main className="align-center container mx-auto items-center space-y-8 px-4 py-8 text-center md:space-y-10">
          <div className="align-center mx-auto w-full max-w-5xl items-center justify-center space-y-2 text-center md:space-y-4">
            {/* space between text sm */}
            <div className="w-full text-center">
              <h1 className="text-[38px] font-black leading-[110%] md:text-[72px] md:leading-[120%]">
                Generate
                <br className="md:hidden" /> viral-ready clips
              </h1>
              <h1 className="flex items-center justify-center gap-2 text-[38px] font-black leading-[110%] md:gap-4 md:text-[72px] md:leading-[120%]">
                in <SparklesText className="font-black" text="seconds." />
              </h1>
            </div>
            <p className="text-md mb-8 px-12 md:text-3xl">
              Your all-in-one tool for creating AI voiceovers, engaging subtitles, optimized
              gameplay, and more.
              {/* Instantly generate viral clips with endless captions, tools, and overlays. Short-form
              content, made simple. */}
            </p>
          </div>

          <ShinyButton
            text="Try Crayo now"
            className="items-center space-x-2.5 shadow-[0_13px_22px_rgba(0,0,0,0.10)] drop-shadow-[0_-9px_22px_rgba(255,255,255,0.87)]"
            href="https://crayo.ai/dashboard"
          />

          <div className="mt-8 pb-24">
            <p className="text-md mb-4 md:text-xl">Powering top creators on</p>
            <div className="align-center flex justify-center space-x-4 md:space-x-8">
              <Image
                src="https://cdn-crayo.com/lp/public/hero-tiktok.png"
                alt="TikTok"
                height={48}
                width={110}
                unoptimized
                className="h-[24px] w-[75px] md:h-[42px] md:w-[150px]"
              />
              <Image
                src="https://cdn-crayo.com/lp/public/hero-shorts.png"
                alt="Shorts"
                height={48}
                width={90}
                unoptimized
                className="h-[24px] w-[65px] md:h-[42px] md:w-[115px]"
              />
              <Image
                src="https://cdn-crayo.com/lp/public/hero-instagram.png"
                alt="Instagram"
                height={48}
                width={125}
                unoptimized
                className="h-[24px] w-[80px] md:h-[42px] md:w-[160px]"
              />
            </div>
          </div>
          {/* <Image 
            src="/heroClip.png" 
            alt="clip" 
            className="hidden lg:block lg:w-[350px] lg:h-[350px] absolute bottom-0 right-0 z-10" 
            height={350} 
            width={350} 
          /> */}
        </main>
      </div>
      <style jsx>{`
        .flip-container {
          position: relative;
          width: 74px;
          height: 80px;
          background: #ffffff33;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: inset 0 0 0 4px rgba(7, 106, 255, 0.2);
        }

        .flip-number {
          font-family: "Ambit", sans-serif;
          font-size: 32px;
          font-weight: bold;
          background: linear-gradient(to bottom, #076aff 0%, #0451c7 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 1;
          text-align: center;
          tracking: -2.5rem;
        }

        .divider-line {
          position: absolute;
          width: 90%;
          height: 2px;
          background: rgba(0, 0, 0, 0.2);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        /* Updated side hooks - thinner and inside */
        .flip-container::before,
        .flip-container::after {
          content: "";
          position: absolute;
          width: 6px;
          height: 20px;
          background: linear-gradient(
            to bottom,
            rgb(0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 50%,
            rgb(0, 0, 0) 100%
          );
          border-radius: 3px;
        }

        .flip-container::before {
          left: -2px;
          top: 50%;
          transform: translateY(-50%);
        }

        .flip-container::after {
          right: -2px;
          top: 50%;
          transform: translateY(-50%);
        }
      `}</style>
    </div>
  );
};

export default Hero;
